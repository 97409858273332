import { Helmet } from 'react-helmet-async'
import { Redirect } from 'react-router-dom'
import { GridItem } from '@which/seatbelt'

import type { VerticalMyAppointmentsPageQuery } from '../../../../generated/frontend'
import { Breadcrumb } from '../../../../shared/components/Breadcrumb'
import { usePageProps } from '../../../../shared/usePageProps'
import { AllAppointments } from '../../components/AllAppointments'
import { MyAppointmentHeader } from '../../components/MyAppointmentHeader'
import styles from './components.module.scss'

export const getMyAppointmentsVerticalComponents = (
  data: VerticalMyAppointmentsPageQuery['verticalPage']
) => {
  const { header } = data
  return {
    Breadcrumb: () => (
      <Breadcrumb
        heading={{ text: header.title }}
        currentPage={{ text: header.title, href: '' }}
        links={[]}
      />
    ),
    MyAppointments: ({ ...props }: { title: string; standfirst: string; description: string }) => {
      const { userAccessState: { userLoggedIn = false } = {} } = usePageProps()

      if (!userLoggedIn) {
        return <Redirect to="/login?return_url=my-appointments" />
      }

      return (
        <>
          <Helmet>
            <meta content="noindex" name="robots" />
            <title>My Appointments - book or manage your appointments</title>
            <meta content={'My Appointments - book or manage your appointments'} name="title" />
            <meta content="summary" name="twitter:card" />
            <meta
              content={'My Appointments - book Money Helpline, Tech Support and Legal'}
              name="description"
            />
            <meta content="website" property="og:type" />
            <meta content={'https://www.which.co.uk/my-appointments'} property="og:url" />
            <meta
              content={'My Appointments - book or manage your appointments'}
              property="og:title"
            />
            <meta
              content={'My Appointments - book Money Helpline, Tech Support and Legal'}
              property="og:description"
            />
            <meta content={'https://www.which.co.uk/my-appointments'} property="twitter:url" />
            <meta
              content={'My Appointments - book or manage your appointments'}
              property="twitter:title"
            />
            <meta
              content={'My Appointments - book Money Helpline, Tech Support and Legal'}
              property="twitter:description"
            />
          </Helmet>
          <GridItem span={{ large: 10 }} columnStart={{ large: 2 }} className={styles.components}>
            <MyAppointmentHeader {...props} />
            <AllAppointments />
          </GridItem>
        </>
      )
    },
  }
}
