import { useParams } from 'react-router-dom'

import type { VerticalCrPageQuery } from '../../../generated/frontend'
import type { PageParams } from '../../../routes'
import { useBackStack } from '../../../shared/BrowserBackStackProvider'
import { getDataLayerScripts } from '../../../shared/data-layer'
import { getDFPScripts } from '../../../shared/dfp'
import { useFullUrl } from '../../../shared/hooks/useFullUrl'
import type { ContentType } from '../../../shared/types/ContentType'
import type { PageInfo } from '../../../shared/types/PageInfo'
import { getPreviousUrl } from '../../../shared/utils/getPreviousUrl/getPreviousUrl'
import { removeFalsy } from '../../../shared/utils/remove-falsy'
import { removeUrlQueryStringAndFragment } from '../../../shared/utils/remove-url-query-string-and-fragment'
import { useVerticalPageDatalayer } from './useVerticalPageDataLayer'

export const useVerticalDatalayer = () => {
  const { verticalSlug } = useParams<PageParams>()
  const { getPageDatalayer } = useVerticalPageDatalayer()
  const { getFullUrl } = useFullUrl()
  const backStack = useBackStack()
  const previousUrl = getPreviousUrl({ backStack: backStack })

  return {
    getDataLayerItems(
      meta: VerticalCrPageQuery['verticalPage']['meta'],
      callout?: VerticalCrPageQuery['verticalPage']['callout']
    ) {
      const { dataLayer = [] } = meta || {}
      const pageInfo: PageInfo = {
        wcdPageUrl: removeUrlQueryStringAndFragment(getFullUrl()),
        vertical: verticalSlug,
        content_type: getContentType(verticalSlug as ContentType),
        ...(previousUrl && { previousWcdPageUrl: previousUrl }),
      }
      const mappedPageInfo = removeFalsy(getPageDatalayer(pageInfo, callout))

      return [
        ...getDataLayerScripts([...dataLayer, mappedPageInfo]),
        ...getDFPScripts(dataLayer, mappedPageInfo),
      ]
    },
  }
}

///////// IMPLEMENTATION /////////

const getContentType = (context: ContentType) => {
  switch (context) {
    case 'consumer-rights':
      return 'Homepage'
    case 'news':
      return 'news'
    case 'policy-and-insight':
      return 'homepage'
    default:
      return 'vertical top'
  }
}
