import type { FunctionComponent } from 'react'
import React from 'react'
import { Grid, GridItem, Picture, TypographyV2 as Typography } from '@which/seatbelt'

import styles from './CampaignsHeader.module.scss'

export const CampaignsHeader: FunctionComponent<Props> = ({
  header,
  standfirst,
  image,
  bgColour,
}) => {
  return (
    <GridItem
      span={{ large: 12 }}
      columnStart={{ large: 1 }}
      className={styles.campaignsHeader}
      {...(bgColour && { style: { backgroundColor: bgColour } })}
    >
      <Grid data-testid="campaigns-header" className={styles.campaignsHeaderContainer}>
        <GridItem
          span={{ large: 6 }}
          columnStart={{ large: 1 }}
          className={styles.campaignsHeaderContent}
        >
          <Typography
            tag="h1"
            textStyle="sb-text-heading-page-title"
            data-testid="campaigns-header-title"
          >
            {header}
          </Typography>
          <Typography
            tag="p"
            className={styles.campaignsHeaderStandfirst}
            textStyle="sb-text-heading-standfirst"
            data-testid="campaigns-header-standfirst"
          >
            {standfirst}
          </Typography>
        </GridItem>

        <GridItem
          span={{ large: 6 }}
          columnStart={{ large: 7 }}
          className={styles.campaignsHeaderImageContainer}
        >
          <Picture src={image} aspectRatioMain="three-to-one" />
        </GridItem>
      </Grid>
    </GridItem>
  )
}

///////// IMPLEMENTATION /////////

export type Props = {
  header: string
  standfirst: string
  image: string
  bgColour: string
}
